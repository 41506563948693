/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Summary } from './Summary';
import {
  SummaryFromJSON,
  SummaryFromJSONTyped,
  SummaryToJSON,
} from './Summary';

/**
 *
 * @export
 * @interface PatchedChatChannel
 */
export interface PatchedChatChannel {
  /**
   *
   * @type {Summary}
   * @memberof PatchedChatChannel
   */
  readonly lastSummary?: Summary;
  /**
   *
   * @type {string}
   * @memberof PatchedChatChannel
   */
  channelUrl?: string;
}

/**
 * Check if a given object implements the PatchedChatChannel interface.
 */
export function instanceOfPatchedChatChannel(value: object): boolean {
  return true;
}

export function PatchedChatChannelFromJSON(json: any): PatchedChatChannel {
  return PatchedChatChannelFromJSONTyped(json, false);
}

export function PatchedChatChannelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedChatChannel {
  if (json == null) {
    return json;
  }
  return {
    lastSummary:
      json['last_summary'] == null
        ? undefined
        : SummaryFromJSON(json['last_summary']),
    channelUrl: json['channel_url'] == null ? undefined : json['channel_url'],
  };
}

export function PatchedChatChannelToJSON(
  value?: PatchedChatChannel | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    channel_url: value['channelUrl'],
  };
}
