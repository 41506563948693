/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { LanguageEnum } from './LanguageEnum';
import {
  LanguageEnumFromJSON,
  LanguageEnumFromJSONTyped,
  LanguageEnumToJSON,
} from './LanguageEnum';
import type { Workflow } from './Workflow';
import {
  WorkflowFromJSON,
  WorkflowFromJSONTyped,
  WorkflowToJSON,
} from './Workflow';

/**
 *
 * @export
 * @interface WorkflowPatientEnrollment
 */
export interface WorkflowPatientEnrollment {
  /**
   *
   * @type {number}
   * @memberof WorkflowPatientEnrollment
   */
  readonly id: number;
  /**
   *
   * @type {Workflow}
   * @memberof WorkflowPatientEnrollment
   */
  readonly workflow: Workflow;
  /**
   *
   * @type {Date}
   * @memberof WorkflowPatientEnrollment
   */
  readonly updated: Date | null;
  /**
   *
   * @type {Date}
   * @memberof WorkflowPatientEnrollment
   */
  readonly created: Date | null;
  /**
   *
   * @type {Date}
   * @memberof WorkflowPatientEnrollment
   */
  endDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof WorkflowPatientEnrollment
   */
  isActive?: boolean;
  /**
   *
   * @type {LanguageEnum}
   * @memberof WorkflowPatientEnrollment
   */
  language?: LanguageEnum;
  /**
   *
   * @type {number}
   * @memberof WorkflowPatientEnrollment
   */
  patient: number;
  /**
   *
   * @type {number}
   * @memberof WorkflowPatientEnrollment
   */
  rtmEnrollment?: number;
}

/**
 * Check if a given object implements the WorkflowPatientEnrollment interface.
 */
export function instanceOfWorkflowPatientEnrollment(value: object): boolean {
  if (!('id' in value)) return false;
  if (!('workflow' in value)) return false;
  if (!('updated' in value)) return false;
  if (!('created' in value)) return false;
  if (!('patient' in value)) return false;
  return true;
}

export function WorkflowPatientEnrollmentFromJSON(
  json: any,
): WorkflowPatientEnrollment {
  return WorkflowPatientEnrollmentFromJSONTyped(json, false);
}

export function WorkflowPatientEnrollmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WorkflowPatientEnrollment {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    workflow: WorkflowFromJSON(json['workflow']),
    updated: json['updated'] == null ? null : new Date(json['updated']),
    created: json['created'] == null ? null : new Date(json['created']),
    endDate: json['end_date'] == null ? undefined : new Date(json['end_date']),
    isActive: json['is_active'] == null ? undefined : json['is_active'],
    language:
      json['language'] == null
        ? undefined
        : LanguageEnumFromJSON(json['language']),
    patient: json['patient'],
    rtmEnrollment:
      json['rtm_enrollment'] == null ? undefined : json['rtm_enrollment'],
  };
}

export function WorkflowPatientEnrollmentToJSON(
  value?: WorkflowPatientEnrollment | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    end_date:
      value['endDate'] == null
        ? undefined
        : (value['endDate'] as any).toISOString().substring(0, 10),
    is_active: value['isActive'],
    language: LanguageEnumToJSON(value['language']),
    patient: value['patient'],
    rtm_enrollment: value['rtmEnrollment'],
  };
}
