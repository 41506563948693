/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { OperationEnum } from './OperationEnum';
import {
  OperationEnumFromJSON,
  OperationEnumFromJSONTyped,
  OperationEnumToJSON,
} from './OperationEnum';

/**
 * Serializer for bulk reconciliation of BillingInstances.
 *
 * This serializer is used to reconcile or unreconcile multiple BillingInstances
 * at once. It validates the input data and performs the reconciliation operation
 * on the BillingInstances.
 * @export
 * @interface BillingInstanceBulkBill
 */
export interface BillingInstanceBulkBill {
  /**
   * List of BillingInstance UUIDs to be updated.
   * @type {Array<string>}
   * @memberof BillingInstanceBulkBill
   */
  uuids: Array<string>;
  /**
   * Operation to be performed.
   *
   * * `CANCEL` - CANCEL
   * * `UNCANCEL` - UNCANCEL
   * * `RECONCILE` - RECONCILE
   * * `UNRECONCILE` - UNRECONCILE
   * @type {OperationEnum}
   * @memberof BillingInstanceBulkBill
   */
  operation: OperationEnum;
  /**
   * Datetime to set for the reconciliation_datetime field.
   * @type {Date}
   * @memberof BillingInstanceBulkBill
   */
  reconciliationDatetime?: Date;
}

/**
 * Check if a given object implements the BillingInstanceBulkBill interface.
 */
export function instanceOfBillingInstanceBulkBill(value: object): boolean {
  if (!('uuids' in value)) return false;
  if (!('operation' in value)) return false;
  return true;
}

export function BillingInstanceBulkBillFromJSON(
  json: any,
): BillingInstanceBulkBill {
  return BillingInstanceBulkBillFromJSONTyped(json, false);
}

export function BillingInstanceBulkBillFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BillingInstanceBulkBill {
  if (json == null) {
    return json;
  }
  return {
    uuids: json['uuids'],
    operation: OperationEnumFromJSON(json['operation']),
    reconciliationDatetime:
      json['reconciliation_datetime'] == null
        ? undefined
        : new Date(json['reconciliation_datetime']),
  };
}

export function BillingInstanceBulkBillToJSON(
  value?: BillingInstanceBulkBill | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    uuids: value['uuids'],
    operation: OperationEnumToJSON(value['operation']),
    reconciliation_datetime:
      value['reconciliationDatetime'] == null
        ? undefined
        : value['reconciliationDatetime'].toISOString(),
  };
}
