/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { LanguageEnum } from './LanguageEnum';
import {
  LanguageEnumFromJSON,
  LanguageEnumFromJSONTyped,
  LanguageEnumToJSON,
} from './LanguageEnum';
import type { PatientRTMEnrollmentDurationEnum } from './PatientRTMEnrollmentDurationEnum';
import {
  PatientRTMEnrollmentDurationEnumFromJSON,
  PatientRTMEnrollmentDurationEnumFromJSONTyped,
  PatientRTMEnrollmentDurationEnumToJSON,
} from './PatientRTMEnrollmentDurationEnum';

/**
 *
 * @export
 * @interface PatientRTMEnrollment
 */
export interface PatientRTMEnrollment {
  /**
   *
   * @type {PatientRTMEnrollmentDurationEnum}
   * @memberof PatientRTMEnrollment
   */
  duration: PatientRTMEnrollmentDurationEnum;
  /**
   *
   * @type {string}
   * @memberof PatientRTMEnrollment
   */
  workflow: string | null;
  /**
   *
   * @type {LanguageEnum}
   * @memberof PatientRTMEnrollment
   */
  language: LanguageEnum;
  /**
   *
   * @type {boolean}
   * @memberof PatientRTMEnrollment
   */
  isNewPoc?: boolean;
  /**
   *
   * @type {number}
   * @memberof PatientRTMEnrollment
   */
  enrolledBy?: number;
}

/**
 * Check if a given object implements the PatientRTMEnrollment interface.
 */
export function instanceOfPatientRTMEnrollment(value: object): boolean {
  if (!('duration' in value)) return false;
  if (!('workflow' in value)) return false;
  if (!('language' in value)) return false;
  return true;
}

export function PatientRTMEnrollmentFromJSON(json: any): PatientRTMEnrollment {
  return PatientRTMEnrollmentFromJSONTyped(json, false);
}

export function PatientRTMEnrollmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientRTMEnrollment {
  if (json == null) {
    return json;
  }
  return {
    duration: PatientRTMEnrollmentDurationEnumFromJSON(json['duration']),
    workflow: json['workflow'],
    language: LanguageEnumFromJSON(json['language']),
    isNewPoc: json['is_new_poc'] == null ? undefined : json['is_new_poc'],
    enrolledBy: json['enrolled_by'] == null ? undefined : json['enrolled_by'],
  };
}

export function PatientRTMEnrollmentToJSON(
  value?: PatientRTMEnrollment | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    duration: PatientRTMEnrollmentDurationEnumToJSON(value['duration']),
    workflow: value['workflow'],
    language: LanguageEnumToJSON(value['language']),
    is_new_poc: value['isNewPoc'],
    enrolled_by: value['enrolledBy'],
  };
}
