/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ProviderSerializerSlim } from './ProviderSerializerSlim';
import {
  ProviderSerializerSlimFromJSON,
  ProviderSerializerSlimFromJSONTyped,
  ProviderSerializerSlimToJSON,
} from './ProviderSerializerSlim';
import type { Workflow } from './Workflow';
import {
  WorkflowFromJSON,
  WorkflowFromJSONTyped,
  WorkflowToJSON,
} from './Workflow';

/**
 *
 * @export
 * @interface Clinic
 */
export interface Clinic {
  /**
   *
   * @type {string}
   * @memberof Clinic
   */
  readonly uuid: string;
  /**
   *
   * @type {string}
   * @memberof Clinic
   */
  readonly name: string;
  /**
   *
   * @type {string}
   * @memberof Clinic
   */
  domain?: string;
  /**
   *
   * @type {Array<ProviderSerializerSlim>}
   * @memberof Clinic
   */
  readonly providers: Array<ProviderSerializerSlim>;
  /**
   *
   * @type {Array<Workflow>}
   * @memberof Clinic
   */
  readonly workflows: Array<Workflow>;
}

/**
 * Check if a given object implements the Clinic interface.
 */
export function instanceOfClinic(value: object): boolean {
  if (!('uuid' in value)) return false;
  if (!('name' in value)) return false;
  if (!('providers' in value)) return false;
  if (!('workflows' in value)) return false;
  return true;
}

export function ClinicFromJSON(json: any): Clinic {
  return ClinicFromJSONTyped(json, false);
}

export function ClinicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Clinic {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    domain: json['domain'] == null ? undefined : json['domain'],
    providers: (json['providers'] as Array<any>).map(
      ProviderSerializerSlimFromJSON,
    ),
    workflows: (json['workflows'] as Array<any>).map(WorkflowFromJSON),
  };
}

export function ClinicToJSON(value?: Clinic | null): any {
  if (value == null) {
    return value;
  }
  return {
    domain: value['domain'],
  };
}
