/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface BillingBillinginstancesReconcileInBulkCreate201Response
 */
export interface BillingBillinginstancesReconcileInBulkCreate201Response {
  /**
   * UUIDs of excluded instances
   * @type {Array<string>}
   * @memberof BillingBillinginstancesReconcileInBulkCreate201Response
   */
  excludedInstances?: Array<string>;
}

/**
 * Check if a given object implements the BillingBillinginstancesReconcileInBulkCreate201Response interface.
 */
export function instanceOfBillingBillinginstancesReconcileInBulkCreate201Response(
  value: object,
): boolean {
  return true;
}

export function BillingBillinginstancesReconcileInBulkCreate201ResponseFromJSON(
  json: any,
): BillingBillinginstancesReconcileInBulkCreate201Response {
  return BillingBillinginstancesReconcileInBulkCreate201ResponseFromJSONTyped(
    json,
    false,
  );
}

export function BillingBillinginstancesReconcileInBulkCreate201ResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BillingBillinginstancesReconcileInBulkCreate201Response {
  if (json == null) {
    return json;
  }
  return {
    excludedInstances:
      json['excluded_instances'] == null
        ? undefined
        : json['excluded_instances'],
  };
}

export function BillingBillinginstancesReconcileInBulkCreate201ResponseToJSON(
  value?: BillingBillinginstancesReconcileInBulkCreate201Response | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    excluded_instances: value['excludedInstances'],
  };
}
