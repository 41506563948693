/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
  CompanyFromJSON,
  CompanyFromJSONTyped,
  CompanyToJSON,
} from './Company';
import type { Provider } from './Provider';
import {
  ProviderFromJSON,
  ProviderFromJSONTyped,
  ProviderToJSON,
} from './Provider';

/**
 *
 * @export
 * @interface PatchedProviderMetadata
 */
export interface PatchedProviderMetadata {
  /**
   *
   * @type {Company}
   * @memberof PatchedProviderMetadata
   */
  readonly company?: Company;
  /**
   *
   * @type {Provider}
   * @memberof PatchedProviderMetadata
   */
  readonly providerMetadata?: Provider;
}

/**
 * Check if a given object implements the PatchedProviderMetadata interface.
 */
export function instanceOfPatchedProviderMetadata(value: object): boolean {
  return true;
}

export function PatchedProviderMetadataFromJSON(
  json: any,
): PatchedProviderMetadata {
  return PatchedProviderMetadataFromJSONTyped(json, false);
}

export function PatchedProviderMetadataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedProviderMetadata {
  if (json == null) {
    return json;
  }
  return {
    company:
      json['company'] == null ? undefined : CompanyFromJSON(json['company']),
    providerMetadata:
      json['provider_metadata'] == null
        ? undefined
        : ProviderFromJSON(json['provider_metadata']),
  };
}

export function PatchedProviderMetadataToJSON(
  value?: PatchedProviderMetadata | null,
): any {
  if (value == null) {
    return value;
  }
  return {};
}
