/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { BillingInstance } from './BillingInstance';
import {
  BillingInstanceFromJSON,
  BillingInstanceFromJSONTyped,
  BillingInstanceToJSON,
} from './BillingInstance';

/**
 *
 * @export
 * @interface PaginatedBillingInstanceList
 */
export interface PaginatedBillingInstanceList {
  /**
   *
   * @type {number}
   * @memberof PaginatedBillingInstanceList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedBillingInstanceList
   */
  next?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedBillingInstanceList
   */
  previous?: string;
  /**
   *
   * @type {Array<BillingInstance>}
   * @memberof PaginatedBillingInstanceList
   */
  results?: Array<BillingInstance>;
}

/**
 * Check if a given object implements the PaginatedBillingInstanceList interface.
 */
export function instanceOfPaginatedBillingInstanceList(value: object): boolean {
  return true;
}

export function PaginatedBillingInstanceListFromJSON(
  json: any,
): PaginatedBillingInstanceList {
  return PaginatedBillingInstanceListFromJSONTyped(json, false);
}

export function PaginatedBillingInstanceListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBillingInstanceList {
  if (json == null) {
    return json;
  }
  return {
    count: json['count'] == null ? undefined : json['count'],
    next: json['next'] == null ? undefined : json['next'],
    previous: json['previous'] == null ? undefined : json['previous'],
    results:
      json['results'] == null
        ? undefined
        : (json['results'] as Array<any>).map(BillingInstanceFromJSON),
  };
}

export function PaginatedBillingInstanceListToJSON(
  value?: PaginatedBillingInstanceList | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    count: value['count'],
    next: value['next'],
    previous: value['previous'],
    results:
      value['results'] == null
        ? undefined
        : (value['results'] as Array<any>).map(BillingInstanceToJSON),
  };
}
