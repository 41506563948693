/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserSerializerFullName
 */
export interface UserSerializerFullName {
  /**
   *
   * @type {number}
   * @memberof UserSerializerFullName
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof UserSerializerFullName
   */
  readonly fullName: string;
  /**
   *
   * @type {string}
   * @memberof UserSerializerFullName
   */
  firstName?: string;
}

/**
 * Check if a given object implements the UserSerializerFullName interface.
 */
export function instanceOfUserSerializerFullName(value: object): boolean {
  if (!('id' in value)) return false;
  if (!('fullName' in value)) return false;
  return true;
}

export function UserSerializerFullNameFromJSON(
  json: any,
): UserSerializerFullName {
  return UserSerializerFullNameFromJSONTyped(json, false);
}

export function UserSerializerFullNameFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSerializerFullName {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    fullName: json['full_name'],
    firstName: json['first_name'] == null ? undefined : json['first_name'],
  };
}

export function UserSerializerFullNameToJSON(
  value?: UserSerializerFullName | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    first_name: value['firstName'],
  };
}
