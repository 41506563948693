/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ActivityTypesEnum } from './ActivityTypesEnum';
import {
  ActivityTypesEnumFromJSON,
  ActivityTypesEnumFromJSONTyped,
  ActivityTypesEnumToJSON,
} from './ActivityTypesEnum';
import type { ProviderSerializerSlim } from './ProviderSerializerSlim';
import {
  ProviderSerializerSlimFromJSON,
  ProviderSerializerSlimFromJSONTyped,
  ProviderSerializerSlimToJSON,
} from './ProviderSerializerSlim';

/**
 *
 * @export
 * @interface InteractionLog
 */
export interface InteractionLog {
  /**
   *
   * @type {number}
   * @memberof InteractionLog
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof InteractionLog
   */
  patient: number;
  /**
   *
   * @type {ProviderSerializerSlim}
   * @memberof InteractionLog
   */
  provider: ProviderSerializerSlim;
  /**
   *
   * @type {number}
   * @memberof InteractionLog
   */
  durationSecs: number;
  /**
   *
   * @type {Array<ActivityTypesEnum>}
   * @memberof InteractionLog
   */
  activityTypes?: Array<ActivityTypesEnum>;
  /**
   *
   * @type {string}
   * @memberof InteractionLog
   */
  noteObservedTrends?: string;
  /**
   *
   * @type {string}
   * @memberof InteractionLog
   */
  notePlanModification?: string;
  /**
   *
   * @type {Date}
   * @memberof InteractionLog
   */
  interactedDatetime: Date;
  /**
   *
   * @type {boolean}
   * @memberof InteractionLog
   */
  autoGenerated?: boolean;
  /**
   *
   * @type {number}
   * @memberof InteractionLog
   */
  billinginstance?: number;
}

/**
 * Check if a given object implements the InteractionLog interface.
 */
export function instanceOfInteractionLog(value: object): boolean {
  if (!('id' in value)) return false;
  if (!('patient' in value)) return false;
  if (!('provider' in value)) return false;
  if (!('durationSecs' in value)) return false;
  if (!('interactedDatetime' in value)) return false;
  return true;
}

export function InteractionLogFromJSON(json: any): InteractionLog {
  return InteractionLogFromJSONTyped(json, false);
}

export function InteractionLogFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InteractionLog {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    patient: json['patient'],
    provider: ProviderSerializerSlimFromJSON(json['provider']),
    durationSecs: json['duration_secs'],
    activityTypes:
      json['activity_types'] == null
        ? undefined
        : (json['activity_types'] as Array<any>).map(ActivityTypesEnumFromJSON),
    noteObservedTrends:
      json['note_observed_trends'] == null
        ? undefined
        : json['note_observed_trends'],
    notePlanModification:
      json['note_plan_modification'] == null
        ? undefined
        : json['note_plan_modification'],
    interactedDatetime: new Date(json['interacted_datetime']),
    autoGenerated:
      json['auto_generated'] == null ? undefined : json['auto_generated'],
    billinginstance:
      json['billinginstance'] == null ? undefined : json['billinginstance'],
  };
}

export function InteractionLogToJSON(value?: InteractionLog | null): any {
  if (value == null) {
    return value;
  }
  return {
    patient: value['patient'],
    provider: ProviderSerializerSlimToJSON(value['provider']),
    duration_secs: value['durationSecs'],
    activity_types:
      value['activityTypes'] == null
        ? undefined
        : (value['activityTypes'] as Array<any>).map(ActivityTypesEnumToJSON),
    note_observed_trends: value['noteObservedTrends'],
    note_plan_modification: value['notePlanModification'],
    interacted_datetime: value['interactedDatetime'].toISOString(),
    auto_generated: value['autoGenerated'],
    billinginstance: value['billinginstance'],
  };
}
