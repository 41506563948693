import { combineReducers } from 'redux';

import { Login, Session } from './login_reducer';
import { Reset } from './reset_password_reducer';

export default combineReducers({
  Session,
  // purge
  Login,
  Reset,
});
