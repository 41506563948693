/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { PatientCareTeam } from './PatientCareTeam';
import {
  PatientCareTeamFromJSON,
  PatientCareTeamFromJSONTyped,
  PatientCareTeamToJSON,
} from './PatientCareTeam';
import type { PatientRTMEnrollment } from './PatientRTMEnrollment';
import {
  PatientRTMEnrollmentFromJSON,
  PatientRTMEnrollmentFromJSONTyped,
  PatientRTMEnrollmentToJSON,
} from './PatientRTMEnrollment';
import type { User } from './User';
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from './User';

/**
 *
 * @export
 * @interface PatientCreate
 */
export interface PatientCreate {
  /**
   *
   * @type {User}
   * @memberof PatientCreate
   */
  user: User;
  /**
   *
   * @type {PatientCareTeam}
   * @memberof PatientCreate
   */
  careTeam?: PatientCareTeam;
  /**
   *
   * @type {PatientRTMEnrollment}
   * @memberof PatientCreate
   */
  rtmEnrollment: PatientRTMEnrollment;
}

/**
 * Check if a given object implements the PatientCreate interface.
 */
export function instanceOfPatientCreate(value: object): boolean {
  if (!('user' in value)) return false;
  if (!('rtmEnrollment' in value)) return false;
  return true;
}

export function PatientCreateFromJSON(json: any): PatientCreate {
  return PatientCreateFromJSONTyped(json, false);
}

export function PatientCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientCreate {
  if (json == null) {
    return json;
  }
  return {
    user: UserFromJSON(json['user']),
    careTeam:
      json['care_team'] == null
        ? undefined
        : PatientCareTeamFromJSON(json['care_team']),
    rtmEnrollment: PatientRTMEnrollmentFromJSON(json['rtm_enrollment']),
  };
}

export function PatientCreateToJSON(value?: PatientCreate | null): any {
  if (value == null) {
    return value;
  }
  return {
    user: UserToJSON(value['user']),
    care_team: PatientCareTeamToJSON(value['careTeam']),
    rtm_enrollment: PatientRTMEnrollmentToJSON(value['rtmEnrollment']),
  };
}
