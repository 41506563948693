/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface PatientListViaPost
 */
export interface PatientListViaPost {
  /**
   *
   * @type {Array<string>}
   * @memberof PatientListViaPost
   */
  sendbirdChannelUrls?: Array<string>;
}

/**
 * Check if a given object implements the PatientListViaPost interface.
 */
export function instanceOfPatientListViaPost(value: object): boolean {
  return true;
}

export function PatientListViaPostFromJSON(json: any): PatientListViaPost {
  return PatientListViaPostFromJSONTyped(json, false);
}

export function PatientListViaPostFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientListViaPost {
  if (json == null) {
    return json;
  }
  return {
    sendbirdChannelUrls:
      json['sendbird_channel_urls'] == null
        ? undefined
        : json['sendbird_channel_urls'],
  };
}

export function PatientListViaPostToJSON(
  value?: PatientListViaPost | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    sendbird_channel_urls: value['sendbirdChannelUrls'],
  };
}
