/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface RTMCycle
 */
export interface RTMCycle {
  /**
   *
   * @type {Date}
   * @memberof RTMCycle
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof RTMCycle
   */
  endDate: Date;
  /**
   *
   * @type {number}
   * @memberof RTMCycle
   */
  patientInteractionDayCount?: number;
  /**
   *
   * @type {string}
   * @memberof RTMCycle
   */
  readonly daysLeftInCycle: string;
  /**
   *
   * @type {number}
   * @memberof RTMCycle
   */
  cycleNumber?: number;
}

/**
 * Check if a given object implements the RTMCycle interface.
 */
export function instanceOfRTMCycle(value: object): boolean {
  if (!('startDate' in value)) return false;
  if (!('endDate' in value)) return false;
  if (!('daysLeftInCycle' in value)) return false;
  return true;
}

export function RTMCycleFromJSON(json: any): RTMCycle {
  return RTMCycleFromJSONTyped(json, false);
}

export function RTMCycleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RTMCycle {
  if (json == null) {
    return json;
  }
  return {
    startDate: new Date(json['start_date']),
    endDate: new Date(json['end_date']),
    patientInteractionDayCount:
      json['patient_interaction_day_count'] == null
        ? undefined
        : json['patient_interaction_day_count'],
    daysLeftInCycle: json['days_left_in_cycle'],
    cycleNumber:
      json['cycle_number'] == null ? undefined : json['cycle_number'],
  };
}

export function RTMCycleToJSON(value?: RTMCycle | null): any {
  if (value == null) {
    return value;
  }
  return {
    start_date: value['startDate'].toISOString().substring(0, 10),
    end_date: value['endDate'].toISOString().substring(0, 10),
    patient_interaction_day_count: value['patientInteractionDayCount'],
    cycle_number: value['cycleNumber'],
  };
}
