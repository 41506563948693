/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { DurationF28Enum } from './DurationF28Enum';
import {
  DurationF28EnumFromJSON,
  DurationF28EnumFromJSONTyped,
  DurationF28EnumToJSON,
} from './DurationF28Enum';
import type { ExtendDurationEnum } from './ExtendDurationEnum';
import {
  ExtendDurationEnumFromJSON,
  ExtendDurationEnumFromJSONTyped,
  ExtendDurationEnumToJSON,
} from './ExtendDurationEnum';
import type { RTMEnrollmentStatusEnum } from './RTMEnrollmentStatusEnum';
import {
  RTMEnrollmentStatusEnumFromJSON,
  RTMEnrollmentStatusEnumFromJSONTyped,
  RTMEnrollmentStatusEnumToJSON,
} from './RTMEnrollmentStatusEnum';
import type { UnenrolledReasonEnum } from './UnenrolledReasonEnum';
import {
  UnenrolledReasonEnumFromJSON,
  UnenrolledReasonEnumFromJSONTyped,
  UnenrolledReasonEnumToJSON,
} from './UnenrolledReasonEnum';
import type { WorkflowPatientEnrollment } from './WorkflowPatientEnrollment';
import {
  WorkflowPatientEnrollmentFromJSON,
  WorkflowPatientEnrollmentFromJSONTyped,
  WorkflowPatientEnrollmentToJSON,
} from './WorkflowPatientEnrollment';

/**
 *
 * @export
 * @interface PatchedRTMEnrollment
 */
export interface PatchedRTMEnrollment {
  /**
   *
   * @type {number}
   * @memberof PatchedRTMEnrollment
   */
  readonly id?: number;
  /**
   *
   * @type {RTMEnrollmentStatusEnum}
   * @memberof PatchedRTMEnrollment
   */
  status?: RTMEnrollmentStatusEnum;
  /**
   *
   * @type {WorkflowPatientEnrollment}
   * @memberof PatchedRTMEnrollment
   */
  readonly smsWorkflowEnrollment?: WorkflowPatientEnrollment;
  /**
   *
   * @type {DurationF28Enum}
   * @memberof PatchedRTMEnrollment
   */
  duration?: DurationF28Enum;
  /**
   *
   * @type {number}
   * @memberof PatchedRTMEnrollment
   */
  readonly enrolledBy?: number;
  /**
   *
   * @type {Date}
   * @memberof PatchedRTMEnrollment
   */
  readonly unenrolledAt?: Date;
  /**
   *
   * @type {number}
   * @memberof PatchedRTMEnrollment
   */
  readonly unenrolledBy?: number;
  /**
   *
   * @type {UnenrolledReasonEnum}
   * @memberof PatchedRTMEnrollment
   */
  unenrolledReason?: UnenrolledReasonEnum;
  /**
   *
   * @type {ExtendDurationEnum}
   * @memberof PatchedRTMEnrollment
   */
  extendDuration?: ExtendDurationEnum;
  /**
   *
   * @type {string}
   * @memberof PatchedRTMEnrollment
   */
  workflow?: string;
}

/**
 * Check if a given object implements the PatchedRTMEnrollment interface.
 */
export function instanceOfPatchedRTMEnrollment(value: object): boolean {
  return true;
}

export function PatchedRTMEnrollmentFromJSON(json: any): PatchedRTMEnrollment {
  return PatchedRTMEnrollmentFromJSONTyped(json, false);
}

export function PatchedRTMEnrollmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedRTMEnrollment {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'] == null ? undefined : json['id'],
    status:
      json['status'] == null
        ? undefined
        : RTMEnrollmentStatusEnumFromJSON(json['status']),
    smsWorkflowEnrollment:
      json['sms_workflow_enrollment'] == null
        ? undefined
        : WorkflowPatientEnrollmentFromJSON(json['sms_workflow_enrollment']),
    duration:
      json['duration'] == null
        ? undefined
        : DurationF28EnumFromJSON(json['duration']),
    enrolledBy: json['enrolled_by'] == null ? undefined : json['enrolled_by'],
    unenrolledAt:
      json['unenrolled_at'] == null
        ? undefined
        : new Date(json['unenrolled_at']),
    unenrolledBy:
      json['unenrolled_by'] == null ? undefined : json['unenrolled_by'],
    unenrolledReason:
      json['unenrolled_reason'] == null
        ? undefined
        : UnenrolledReasonEnumFromJSON(json['unenrolled_reason']),
    extendDuration:
      json['extend_duration'] == null
        ? undefined
        : ExtendDurationEnumFromJSON(json['extend_duration']),
    workflow: json['workflow'] == null ? undefined : json['workflow'],
  };
}

export function PatchedRTMEnrollmentToJSON(
  value?: PatchedRTMEnrollment | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    status: RTMEnrollmentStatusEnumToJSON(value['status']),
    duration: DurationF28EnumToJSON(value['duration']),
    unenrolled_reason: UnenrolledReasonEnumToJSON(value['unenrolledReason']),
    extend_duration: ExtendDurationEnumToJSON(value['extendDuration']),
    workflow: value['workflow'],
  };
}
