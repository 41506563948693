/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Feedback } from './Feedback';
import {
  FeedbackFromJSON,
  FeedbackFromJSONTyped,
  FeedbackToJSON,
} from './Feedback';

/**
 *
 * @export
 * @interface PatchedSummary
 */
export interface PatchedSummary {
  /**
   *
   * @type {number}
   * @memberof PatchedSummary
   */
  readonly id?: number;
  /**
   *
   * @type {any}
   * @memberof PatchedSummary
   */
  outputData?: any;
  /**
   *
   * @type {Feedback}
   * @memberof PatchedSummary
   */
  readonly feedback?: Feedback;
}

/**
 * Check if a given object implements the PatchedSummary interface.
 */
export function instanceOfPatchedSummary(value: object): boolean {
  return true;
}

export function PatchedSummaryFromJSON(json: any): PatchedSummary {
  return PatchedSummaryFromJSONTyped(json, false);
}

export function PatchedSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedSummary {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'] == null ? undefined : json['id'],
    outputData: json['output_data'] == null ? undefined : json['output_data'],
    feedback:
      json['feedback'] == null ? undefined : FeedbackFromJSON(json['feedback']),
  };
}

export function PatchedSummaryToJSON(value?: PatchedSummary | null): any {
  if (value == null) {
    return value;
  }
  return {
    output_data: value['outputData'],
  };
}
