/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { FeedbackTypeEnum } from './FeedbackTypeEnum';
import {
  FeedbackTypeEnumFromJSON,
  FeedbackTypeEnumFromJSONTyped,
  FeedbackTypeEnumToJSON,
} from './FeedbackTypeEnum';

/**
 *
 * @export
 * @interface Feedback
 */
export interface Feedback {
  /**
   *
   * @type {FeedbackTypeEnum}
   * @memberof Feedback
   */
  feedbackType?: FeedbackTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  feedbackComment?: string;
}

/**
 * Check if a given object implements the Feedback interface.
 */
export function instanceOfFeedback(value: object): boolean {
  return true;
}

export function FeedbackFromJSON(json: any): Feedback {
  return FeedbackFromJSONTyped(json, false);
}

export function FeedbackFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Feedback {
  if (json == null) {
    return json;
  }
  return {
    feedbackType:
      json['feedback_type'] == null
        ? undefined
        : FeedbackTypeEnumFromJSON(json['feedback_type']),
    feedbackComment:
      json['feedback_comment'] == null ? undefined : json['feedback_comment'],
  };
}

export function FeedbackToJSON(value?: Feedback | null): any {
  if (value == null) {
    return value;
  }
  return {
    feedback_type: FeedbackTypeEnumToJSON(value['feedbackType']),
    feedback_comment: value['feedbackComment'],
  };
}
