/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { CancellationReasonTypeE44Enum } from './CancellationReasonTypeE44Enum';
import {
  CancellationReasonTypeE44EnumFromJSON,
  CancellationReasonTypeE44EnumFromJSONTyped,
  CancellationReasonTypeE44EnumToJSON,
} from './CancellationReasonTypeE44Enum';
import type { InteractionLog } from './InteractionLog';
import {
  InteractionLogFromJSON,
  InteractionLogFromJSONTyped,
  InteractionLogToJSON,
} from './InteractionLog';
import type { PatientSerializerSlim } from './PatientSerializerSlim';
import {
  PatientSerializerSlimFromJSON,
  PatientSerializerSlimFromJSONTyped,
  PatientSerializerSlimToJSON,
} from './PatientSerializerSlim';
import type { ProviderSerializerSlim } from './ProviderSerializerSlim';
import {
  ProviderSerializerSlimFromJSON,
  ProviderSerializerSlimFromJSONTyped,
  ProviderSerializerSlimToJSON,
} from './ProviderSerializerSlim';
import type { RTMCycle } from './RTMCycle';
import {
  RTMCycleFromJSON,
  RTMCycleFromJSONTyped,
  RTMCycleToJSON,
} from './RTMCycle';
import type { StatusB89Enum } from './StatusB89Enum';
import {
  StatusB89EnumFromJSON,
  StatusB89EnumFromJSONTyped,
  StatusB89EnumToJSON,
} from './StatusB89Enum';
import type { TypeAc3Enum } from './TypeAc3Enum';
import {
  TypeAc3EnumFromJSON,
  TypeAc3EnumFromJSONTyped,
  TypeAc3EnumToJSON,
} from './TypeAc3Enum';
import type { WillChargePatientE4bEnum } from './WillChargePatientE4bEnum';
import {
  WillChargePatientE4bEnumFromJSON,
  WillChargePatientE4bEnumFromJSONTyped,
  WillChargePatientE4bEnumToJSON,
} from './WillChargePatientE4bEnum';

/**
 *
 * @export
 * @interface BaseBillingInstance
 */
export interface BaseBillingInstance {
  /**
   *
   * @type {string}
   * @memberof BaseBillingInstance
   */
  readonly uuid: string;
  /**
   *
   * @type {StatusB89Enum}
   * @memberof BaseBillingInstance
   */
  readonly status: StatusB89Enum;
  /**
   *
   * @type {Date}
   * @memberof BaseBillingInstance
   */
  billableOn: Date;
  /**
   *
   * @type {Date}
   * @memberof BaseBillingInstance
   */
  reconciled?: Date;
  /**
   *
   * @type {ProviderSerializerSlim}
   * @memberof BaseBillingInstance
   */
  readonly reconciledBy: ProviderSerializerSlim;
  /**
   *
   * @type {TypeAc3Enum}
   * @memberof BaseBillingInstance
   */
  readonly type: TypeAc3Enum;
  /**
   *
   * @type {RTMCycle}
   * @memberof BaseBillingInstance
   */
  readonly rtmcycle: RTMCycle;
  /**
   *
   * @type {PatientSerializerSlim}
   * @memberof BaseBillingInstance
   */
  readonly patient: PatientSerializerSlim;
  /**
   *
   * @type {number}
   * @memberof BaseBillingInstance
   */
  unitsOf98981?: number;
  /**
   *
   * @type {Array<InteractionLog>}
   * @memberof BaseBillingInstance
   */
  readonly interactionLogs: Array<InteractionLog>;
  /**
   *
   * @type {Date}
   * @memberof BaseBillingInstance
   */
  calendarMonth?: Date;
  /**
   * Will charge the patient for this billing
   *
   * * `YES` - Yes
   * * `NO` - No
   * * `MAYBE` - Maybe
   * @type {WillChargePatientE4bEnum}
   * @memberof BaseBillingInstance
   */
  willChargePatient?: WillChargePatientE4bEnum;
  /**
   *
   * @type {CancellationReasonTypeE44Enum}
   * @memberof BaseBillingInstance
   */
  cancellationReasonType?: CancellationReasonTypeE44Enum;
  /**
   *
   * @type {string}
   * @memberof BaseBillingInstance
   */
  optionalCancellationReasonMessage?: string;
  /**
   *
   * @type {Date}
   * @memberof BaseBillingInstance
   */
  cancelledOn?: Date;
  /**
   *
   * @type {ProviderSerializerSlim}
   * @memberof BaseBillingInstance
   */
  readonly cancelledBy: ProviderSerializerSlim;
}

/**
 * Check if a given object implements the BaseBillingInstance interface.
 */
export function instanceOfBaseBillingInstance(value: object): boolean {
  if (!('uuid' in value)) return false;
  if (!('status' in value)) return false;
  if (!('billableOn' in value)) return false;
  if (!('reconciledBy' in value)) return false;
  if (!('type' in value)) return false;
  if (!('rtmcycle' in value)) return false;
  if (!('patient' in value)) return false;
  if (!('interactionLogs' in value)) return false;
  if (!('cancelledBy' in value)) return false;
  return true;
}

export function BaseBillingInstanceFromJSON(json: any): BaseBillingInstance {
  return BaseBillingInstanceFromJSONTyped(json, false);
}

export function BaseBillingInstanceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BaseBillingInstance {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    status: StatusB89EnumFromJSON(json['status']),
    billableOn: new Date(json['billable_on']),
    reconciled:
      json['reconciled'] == null ? undefined : new Date(json['reconciled']),
    reconciledBy: ProviderSerializerSlimFromJSON(json['reconciled_by']),
    type: TypeAc3EnumFromJSON(json['type']),
    rtmcycle: RTMCycleFromJSON(json['rtmcycle']),
    patient: PatientSerializerSlimFromJSON(json['patient']),
    unitsOf98981:
      json['units_of_98981'] == null ? undefined : json['units_of_98981'],
    interactionLogs: (json['interaction_logs'] as Array<any>).map(
      InteractionLogFromJSON,
    ),
    calendarMonth:
      json['calendar_month'] == null
        ? undefined
        : new Date(json['calendar_month']),
    willChargePatient:
      json['will_charge_patient'] == null
        ? undefined
        : WillChargePatientE4bEnumFromJSON(json['will_charge_patient']),
    cancellationReasonType:
      json['cancellation_reason_type'] == null
        ? undefined
        : CancellationReasonTypeE44EnumFromJSON(
            json['cancellation_reason_type'],
          ),
    optionalCancellationReasonMessage:
      json['optional_cancellation_reason_message'] == null
        ? undefined
        : json['optional_cancellation_reason_message'],
    cancelledOn:
      json['cancelled_on'] == null ? undefined : new Date(json['cancelled_on']),
    cancelledBy: ProviderSerializerSlimFromJSON(json['cancelled_by']),
  };
}

export function BaseBillingInstanceToJSON(
  value?: BaseBillingInstance | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    billable_on: value['billableOn'].toISOString().substring(0, 10),
    reconciled:
      value['reconciled'] == null
        ? undefined
        : (value['reconciled'] as any).toISOString(),
    units_of_98981: value['unitsOf98981'],
    calendar_month:
      value['calendarMonth'] == null
        ? undefined
        : (value['calendarMonth'] as any).toISOString().substring(0, 10),
    will_charge_patient: WillChargePatientE4bEnumToJSON(
      value['willChargePatient'],
    ),
    cancellation_reason_type: CancellationReasonTypeE44EnumToJSON(
      value['cancellationReasonType'],
    ),
    optional_cancellation_reason_message:
      value['optionalCancellationReasonMessage'],
    cancelled_on:
      value['cancelledOn'] == null
        ? undefined
        : (value['cancelledOn'] as any).toISOString(),
  };
}
