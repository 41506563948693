/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface PatientRtmReEnrollCreate200Response
 */
export interface PatientRtmReEnrollCreate200Response {
  /**
   * Indicates the status of patient re-enrollment
   * @type {string}
   * @memberof PatientRtmReEnrollCreate200Response
   */
  message?: string;
}

/**
 * Check if a given object implements the PatientRtmReEnrollCreate200Response interface.
 */
export function instanceOfPatientRtmReEnrollCreate200Response(
  value: object,
): boolean {
  return true;
}

export function PatientRtmReEnrollCreate200ResponseFromJSON(
  json: any,
): PatientRtmReEnrollCreate200Response {
  return PatientRtmReEnrollCreate200ResponseFromJSONTyped(json, false);
}

export function PatientRtmReEnrollCreate200ResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientRtmReEnrollCreate200Response {
  if (json == null) {
    return json;
  }
  return {
    message: json['message'] == null ? undefined : json['message'],
  };
}

export function PatientRtmReEnrollCreate200ResponseToJSON(
  value?: PatientRtmReEnrollCreate200Response | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    message: value['message'],
  };
}
