/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface ChatChannelInvite
 */
export interface ChatChannelInvite {
  /**
   *
   * @type {string}
   * @memberof ChatChannelInvite
   */
  channelUrl?: string;
}

/**
 * Check if a given object implements the ChatChannelInvite interface.
 */
export function instanceOfChatChannelInvite(value: object): boolean {
  return true;
}

export function ChatChannelInviteFromJSON(json: any): ChatChannelInvite {
  return ChatChannelInviteFromJSONTyped(json, false);
}

export function ChatChannelInviteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChatChannelInvite {
  if (json == null) {
    return json;
  }
  return {
    channelUrl: json['channel_url'] == null ? undefined : json['channel_url'],
  };
}

export function ChatChannelInviteToJSON(value?: ChatChannelInvite | null): any {
  if (value == null) {
    return value;
  }
  return {
    channel_url: value['channelUrl'],
  };
}
