/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Type956Enum } from './Type956Enum';
import {
  Type956EnumFromJSON,
  Type956EnumFromJSONTyped,
  Type956EnumToJSON,
} from './Type956Enum';
import type { UserSerializerSlim } from './UserSerializerSlim';
import {
  UserSerializerSlimFromJSON,
  UserSerializerSlimFromJSONTyped,
  UserSerializerSlimToJSON,
} from './UserSerializerSlim';
import type { Workflow } from './Workflow';
import {
  WorkflowFromJSON,
  WorkflowFromJSONTyped,
  WorkflowToJSON,
} from './Workflow';

/**
 *
 * @export
 * @interface Provider
 */
export interface Provider {
  /**
   *
   * @type {UserSerializerSlim}
   * @memberof Provider
   */
  readonly user: UserSerializerSlim;
  /**
   *
   * @type {Type956Enum}
   * @memberof Provider
   */
  type?: Type956Enum;
  /**
   *
   * @type {Array<Workflow>}
   * @memberof Provider
   */
  readonly workflows: Array<Workflow>;
  /**
   *
   * @type {Workflow}
   * @memberof Provider
   */
  readonly defaultWorkflow: Workflow;
}

/**
 * Check if a given object implements the Provider interface.
 */
export function instanceOfProvider(value: object): boolean {
  if (!('user' in value)) return false;
  if (!('workflows' in value)) return false;
  if (!('defaultWorkflow' in value)) return false;
  return true;
}

export function ProviderFromJSON(json: any): Provider {
  return ProviderFromJSONTyped(json, false);
}

export function ProviderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Provider {
  if (json == null) {
    return json;
  }
  return {
    user: UserSerializerSlimFromJSON(json['user']),
    type: json['type'] == null ? undefined : Type956EnumFromJSON(json['type']),
    workflows: (json['workflows'] as Array<any>).map(WorkflowFromJSON),
    defaultWorkflow: WorkflowFromJSON(json['default_workflow']),
  };
}

export function ProviderToJSON(value?: Provider | null): any {
  if (value == null) {
    return value;
  }
  return {
    type: Type956EnumToJSON(value['type']),
  };
}
